import React from "react"
import { Stack, Box, Text, Button } from "@chakra-ui/core"
import { GameDetailsPageData } from "../types"
import { Link } from "gatsby"

interface Props {
  data: GameDetailsPageData
}

const GameDescriptionBox: React.FC<Props> = ({ data }) => {
  return (
    <Stack
      alignItems="center"
      direction={{ base: "column", md: "row" }}
      backgroundColor="gray.800"
      padding={6}
      spacing={6}
    >
      <Box>
        <Text
          fontSize={{ base: "sm", md: "md" }}
          color="gray.200"
          textAlign="left"
        >
          {data.presentation.text}
        </Text>
      </Box>
      <Box>
        <Button
          as={Link}
          to={data.presentation.link}
          borderRadius={0}
          borderWidth={2}
          variant="outline"
          padding={{ base: "15px", md: "30px" }}
          backgroundColor="gray.900"
          _hover={{
            backgroundColor: "gray.700",
          }}
        >
          <Text fontSize={{ base: "lg", md: "2xl" }}>SHOW ME</Text>
        </Button>
      </Box>
    </Stack>
  )
}

export default GameDescriptionBox

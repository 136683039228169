import React from "react"
import { Box, Flex, Heading } from "@chakra-ui/core"
import Logo from "../svg/brand/square-logo.svg"
// import Mustache from "../svg/brand/logo.svg"
import CompanyName from "../svg/brand/company-name.svg"
import Mustache from "../svg/brand/mustache.svg"
import { Link } from "gatsby"

interface Props {}

const height = 50
const color = "#EDF2F7"
const Branding: React.FC<Props> = () => {
  return (
    <Flex
      py={2}
      justifyContent={{ base: "center", md: "flex-start" }}
      borderWidth={0}
      borderColor="gray.400"
      borderRadius={10}
    >
      {/* <Flex
        height={70}
        width={200}
        // backgroundColor="gray.200"
        borderWidth={0}
        borderRadius={10}
        borderColor={color}
        justifyContent="center"
        alignItems="center"
      > */}
      {/* <Mustache
        style={{
          opacity: 0.8,
          fill: color,
          height: 40,
          // position: "absolute",
          // top: 20,
          // left: 96,
        }}
      /> */}
      <Link to="/">
        <Heading
          as="h1"
          fontFamily="Cabin Sketch"
          fontSize={{ base: "3xl", md: "4xl" }}
          color={color}
        >
          MAD MUSTACHE
        </Heading>
      </Link>
      {/* <Mustache style={{ width: 120, height: height, fill: color }} /> */}
      {/* <CompanyName style={{ width: 200, height: height, fill: color }} /> */}
      {/* </Flex> */}
    </Flex>
  )
}

export default Branding

import React from "react"
import { Box, Flex } from "@chakra-ui/core"
import Wrapper from "../components/Wrapper"
import Navigation from "../components/Navigation"
import Branding from "../components/Branding"
import Footer from "../components/Footer"

interface Props {}

const Website: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Box as="header" backgroundColor="gray.800" color="gray.200">
        <Wrapper>
          <Branding />
        </Wrapper>
        <Box backgroundColor="gray.800">
          <Wrapper>
            <Navigation />
          </Wrapper>
        </Box>
      </Box>
      <Box as="main" backgroundColor="gray.700" color="gray.200" paddingY={4}>
        <Wrapper>{children}</Wrapper>
      </Box>
      <Box as="footer" backgroundColor="gray.800" color="gray.200">
        <Wrapper>
          <Footer />
        </Wrapper>
      </Box>
    </>
  )
}

export default Website

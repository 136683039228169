import React from "react"
import { useLocation } from "@reach/router"
import { Box, Flex, Button, Stack } from "@chakra-ui/core"
import { Link } from "gatsby"

interface Props {}

const Navigation: React.FC<Props> = () => {
  return (
    <Flex as="nav" pb={5} justifyContent={{ base: "center", md: "flex-start" }}>
      <Stack direction="row" spacing={3} className="navigation">
        {/* <NavigationLink to="/">HOME</NavigationLink> */}
        <NavigationLink to="/games/">GAMES</NavigationLink>
        <NavigationLink to="/apps/">APPS</NavigationLink>
        {/* <NavigationLink to="/presskits/">PRESSKITS</NavigationLink> */}
      </Stack>
    </Flex>
  )
}

interface NavigationLinkProps {
  to: string
}

const NavigationLink: React.FC<NavigationLinkProps> = ({ children, to }) => {
  return (
    <Link to={to} activeClassName="active">
      {children}
    </Link>
  )
  // return (
  //   <Button
  //     as={Link}
  //     to={to}
  //     borderRadius={0}
  //     backgroundColor={isActive ? "red.500" : "gray.800"}
  //     _active={{
  //       backgroundColor: isActive ? "red.500" : "gray.800",
  //     }}
  //     _hover={{
  //       color: isActive ? "gray.200" : "red.300",
  //       // backgroundColor: isActive ? "red.500" : "red.800",
  //     }}
  //   >
  //     {children}
  //   </Button>
  // )
}

export default Navigation

import React from "react"
import { PageProps } from "gatsby"
import Website from "../templates/Website"
import GamePresentationBox from "../components/GamePresentationBox"
import GameDescriptionBox from "../components/GameDescriptionBox"
import { SimpleGrid } from "@chakra-ui/core"
import StoreBar from "../components/StoreBar"
import ldvelhData from "../../content/ldvelh-data.json"
import comiQuestData from "../../content/comiquest-data.json"
import SEO from "../components/SEO"

interface Props extends PageProps {}

const Index: React.FC<Props> = ({ location }) => {
  return (
    <Website>
      <SEO />
      <SimpleGrid columns={1} spacing={{ base: "20px", md: "25px" }}>
        <GamePresentationBox data={comiQuestData}>
          <StoreBar
            gameId={comiQuestData.gameId}
            stores={comiQuestData.stores}
          />
          <GameDescriptionBox data={comiQuestData} />
        </GamePresentationBox>
        <GamePresentationBox data={ldvelhData}>
          <StoreBar gameId={ldvelhData.gameId} stores={ldvelhData.stores} />
          <GameDescriptionBox data={ldvelhData} />
        </GamePresentationBox>
      </SimpleGrid>
    </Website>
  )
}

export default Index
